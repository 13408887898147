// import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs, FreeMode } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  //scroll top
  // $('.back-to-top').click(function(event){
  //   $("html,body").animate({scrollTop: 0}, 1000);
  // });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  // 導覽列選單開關
  $('.menu-link').click(function(e) {
      e.stopPropagation();
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  $(document).on('click', function(){
    if($('.dropdown').hasClass('active')) {
      $('.dropdown').removeClass('active');
    }
  })


  const indexBanner = new Swiper('.home-banner-swiper.swiper-container', {
    loop: true,                   // 循環模式
    speed: 400,
    effect: 'fade',
    autoplay: {
      delay: 6000,                // 自動播放延遲時間（毫秒）
      disableOnInteraction: false // 互動後是否停止自動播放
    },
    pagination: {
      el: '.swiper-pagination',    // 分頁元素
      clickable: true,             // 分頁是否可點擊
    },
  });

  // dropdown
  $('.dropdown-btn').on('click', function () {
    const id = $(this).attr('id');
    const dropdown_is_active = $('[aria-labelledby=' + id + ']').hasClass("is-active");
    if (dropdown_is_active >= 1) {
      $('[aria-labelledby=' + id + ']').removeClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '',
          'overflow': '',
        })
      }
    } else {
      $(".dropdown-list:not([aria-labelledby=" + id + "]").removeClass('is-active');
      $('[aria-labelledby=' + id + ']').addClass('is-active');
      if (window.innerWidth <= 640) {
        $('body').css({
          'height': '100vh',
          'overflow': 'hidden',
        })
      }
    }
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.dropdown-btn').length) {
      if ($('.dropdown-list').is(":visible")) {
        $(".dropdown-list").removeClass('is-active');
        if (window.innerWidth <= 640) {
          $('body').css({
            'height': '',
            'overflow': '',
          })
        }
      }
    }
  });

  /*-----------------------------*/

  // 手機版按鈕toggle
  $('#navBtn').on('click', function(){
    let navBtn = $('#navBtn');
    let mbMenu = navBtn.siblings('.header-wrapper');

    if(navBtn.hasClass('is-active')) {
      navBtn.removeClass('is-active');
      mbMenu.addClass('hidden');
    } else {
      navBtn.addClass('is-active');
      mbMenu.removeClass('hidden');
    }
  })

  // 設定手機版導覽列按鈕及選單顯示狀態
  function toggleNavDisplay() {
    let navBtn = $('#navBtn');
    let mbMenu = navBtn.siblings('.header-wrapper');
    let windowWidth = $(window).width();

    if (windowWidth > 1024) { // 確保 header-wrapper 在寬度 > 1024px 時不隱藏
      if (mbMenu.hasClass('hidden')) {
        mbMenu.removeClass('hidden');
      }
      if (!navBtn.hasClass('is-active')) {
        navBtn.addClass('is-active');
      }
    } else {
      if (!mbMenu.hasClass('hidden')) {
        mbMenu.addClass('hidden');
      }
      if (navBtn.hasClass('is-active')) {
        navBtn.removeClass('is-active');
      }
    }
  }
  $(function(){ //每當載入時重新檢查並更新狀態
    toggleNavDisplay();
  })
  $(window).on('resize', function(){ //每當瀏覽器寬度改變時重新檢查並更新狀態
    toggleNavDisplay();
  });

  // 產品彙整 Swiper
  const productSwiper = new Swiper('.product-swiper', {
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      375: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      }
    },
  });

  // 產品內頁圖片Swiper－縮圖
  const productImgSwiperThumb = new Swiper('.product-img-swiper-thumb', {
    loop: true,
    spaceBetween: 12,
    pagination: {
      el: '.swiper-pagination', // 如果有分頁器
      clickable: true,
    },
    slidesPerView: 4, // 每次顯示4張縮圖
    watchSlidesProgress: true,
    freeMode: false,
  });

  // 產品內頁圖片Swiper－主圖片
  const productImgSwiper = new Swiper('.product-img-swiper', {
    loop: true,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next', // 如果有導航按鈕
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination', // 如果有分頁器
      clickable: true,
    },
    thumbs: {
      swiper: productImgSwiperThumb,
    }
  });

  // Product-files Tooltip
  $('.tooltip-container > a').on('mouseenter', function(){
    let tooltipText = $(this).text();
    $(this).closest('.tooltip-container').append(`<div class="tooltip">${tooltipText}</div>`);
  }).on('mouseleave', function(){
    $(this).siblings('.tooltip').remove();
  })

  // News Tooltip
  $('.news-tooltip').on('mouseenter', function(){
    let tooltipText = $(this).find('.news-title').text();
    $(this).append(`<div class="tooltip">${tooltipText}</div>`);
  }).on('mouseleave', function(){
    $(this).find('.tooltip').remove();
  })

  // 首頁麵包屑添加class
  $('.breadcrumb-item').each(function() {
    if ($(this).text().trim() === '首頁') {
      $(this).addClass('breadcrumb-home');  // 為"首頁"的麵包屑項目添加類別
    }
  });
});

$(function() {
  // 當頁面載入時，檢查並為第一個 .cases-type 添加 .selected 類別
  const firstCaseType = $('.cases-type').first();
  firstCaseType.addClass('selected');

  // 根據第一個 .selected 標籤的內容，顯示對應的 .case-main-row
  let selectedTypeName = firstCaseType.text().trim();
  $('.case-main-row').addClass('hidden');
  $(`.case-type-tag:contains('${selectedTypeName}')`).closest('.case-main-row').removeClass('hidden');

  // 點擊事件：點擊時設置相應選項並顯示對應的內容
  $('.cases-type').on('click', function() {
      $('.cases-type.selected').removeClass('selected');
      $(this).addClass('selected');

      let selectedTypeName = $(this).text().trim(); // 獲取當前選中的標籤文字
      $('.case-main-row').addClass('hidden');
      $(`.case-type-tag:contains('${selectedTypeName}')`).closest('.case-main-row').removeClass('hidden');
  });
});
